import * as React from "react";
import PageWrapper from "../components/layouts/Page";
import Hero from "../components/pages/home/Hero";
import ScrollVisibilityObserver from "../components/utils/ScrollVisibilityObserver";
import Circles from "../components/pages/home/Circles";
import {
  Section,
  Main,
  FixedWidth,
  Article,
  ArticleColumn,
} from "../components/layout/Semantic";
import { H2, P } from "../components/copy";
import styled from "styled-components";
import { motion } from "framer-motion";
import Content from "../content/home.json";
import {
  RightTopTriangle,
  RightBottomTriangle,
} from "../components/layout/Decorators";
import Button from "../components/controls/Button";
import Cities from "../components/pages/home/Cities";
import theme from "../styles/theme";
import IndustrySquares from "../components/pages/home/IndustrySquares";
import usePageWidth from "../hooks/usePageWidth";

const ContentFixedWidth = styled(FixedWidth)`
  padding: 20px;

  @media screen and (min-width: 1279px) {
    width: 1280px;
    margin: 0 auto;
    padding: 20px 96px;
    margin-top: -96px;
    margin-bottom: -176px;
  }
`;

const FixedWidthP = styled(motion.p)`
  @media screen and (min-width: ${({ width }) =>
      width ? width - 1 + "px" : "639px"}) {
    max-width: ${({ width }) => (width ? width + "px" : "640px")};
  }
  line-height: 2;
`;

const SectionLinks = styled(motion.div)`
  position: relative;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 560px) {
    align-items: flex-start;
  }
`;

const RTTriMod = styled(RightTopTriangle)`
  background: linear-gradient(
    to right,
    ${({ theme }) => theme.COLORS.abyss} 5%,
    ${({ theme }) => theme.COLORS.marine} 50%,
    ${({ theme }) => theme.COLORS.vermilion}
  );
`;

const RBTriMod = styled(RightBottomTriangle)`
  background: repeating-linear-gradient(
    135deg,
    ${({ theme }) => theme.COLORS.rainyday} 0,
    ${({ theme }) => theme.COLORS.rainyday} 1px,
    transparent 1px,
    transparent 8px
  );
`;

const A = styled(Article)`
  flex-direction: column;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    height: ${({ desktopHeight }) =>
      desktopHeight ? desktopHeight + "px" : "unset"};
  }
`;

const AC = styled(ArticleColumn)`
  flex-shrink: 0;
  flex-grow: 1;
  justify-content: center;

  @media screen and (min-width: 800px) {
    flex-basis: 50%;
  }
`;

const IndexPage = (props) => {
  const { isMobile } = usePageWidth();

  return (
    <PageWrapper {...props} name="home">
      <Hero />
      <Main>
        <Section id="about">
          <FixedWidth>
            <RTTriMod height={185} />
          </FixedWidth>
          <ContentFixedWidth style={{}}>
            <H2>{Content.what_we_do.title}</H2>
            <FixedWidthP>{Content.what_we_do.description}</FixedWidthP>
            <SectionLinks>
              <Button label="More about us" href="/about" />
              <Button label="More about our talent" href="/team" />
            </SectionLinks>
          </ContentFixedWidth>
          <FixedWidth>
            <RBTriMod height={isMobile ? 156 : 340} />
          </FixedWidth>
        </Section>

        <Cities />

        <Section id="expertise" style={{ paddingBottom: "56px" }}>
          <FixedWidth style={{ padding: "0 20px" }}>
            <H2 style={{ marginTop: "56px", marginBottom: "64px" }}>
              {Content.our_expertise.title}
            </H2>
            <A desktopHeight={250}>
              <AC style={{ alignItems: "center", minHeight: "325px" }}>
                <ScrollVisibilityObserver>
                  {({ isInView }) => {
                    return (
                      <Circles
                        innerPattern
                        isInView={isInView}
                        size={115}
                        label={Content.our_expertise.one.title}
                      />
                    );
                  }}
                </ScrollVisibilityObserver>
              </AC>
              <AC>
                <P>{Content.our_expertise.one.description}</P>
                <Button href="/services" />
              </AC>
            </A>
            <A desktopHeight={400}>
              <AC style={{ order: isMobile ? "2" : "1" }}>
                <P>{Content.our_expertise.two.description}</P>
                <Button href="/services" />
              </AC>
              <AC
                style={{
                  alignItems: "center",
                  minHeight: "400px",
                  order: isMobile ? "1" : "2",
                }}
              >
                <ScrollVisibilityObserver>
                  {({ isInView }) => {
                    return (
                      <Circles
                        isInView={isInView}
                        size={isMobile ? 115 : 182}
                        label={Content.our_expertise.two.title}
                      />
                    );
                  }}
                </ScrollVisibilityObserver>
              </AC>
            </A>
            <A desktopHeight={500}>
              <AC style={{ alignItems: "center", minHeight: "325px" }}>
                <ScrollVisibilityObserver>
                  {({ isInView }) => {
                    return (
                      <Circles
                        innerPattern
                        isInView={isInView}
                        size={isMobile ? 115 : 138}
                        label={Content.our_expertise.three.title}
                      />
                    );
                  }}
                </ScrollVisibilityObserver>
              </AC>
              <AC>
                <P>{Content.our_expertise.three.description}</P>
                <Button href="/services" />
              </AC>
            </A>
          </FixedWidth>
        </Section>
        <Section
          id="focus"
          style={{
            backgroundColor: theme.COLORS.snow,
            padding: isMobile ? "0" : "20px 56px",
          }}
        >
          <FixedWidth style={{ padding: isMobile ? "0" : "0 20px" }}>
            <H2
              style={{
                padding: "0 20px",
                marginTop: "56px",
                marginBottom: "64px",
              }}
            >
              {Content.industry_focus.title}
            </H2>
            <IndustrySquares isMobile={isMobile} />
          </FixedWidth>
        </Section>
      </Main>
    </PageWrapper>
  );
};

export default IndexPage;
