import * as React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Link } from "gatsby";

const B = styled(motion.button)`
  background-color: transparent;
  --webkit-appearance: none;
  outline: none;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;
  margin: 16px 0;
  padding: 14px 8px;
  width: 100%;

  @media screen and (min-width: 560px) {
    width: unset;
  }
`;

const Label = styled(motion.span)`
  font-family: "Montserrat";
  font-size: ${({ theme }) => theme.FONTS.sizes.default};
  text-transform: uppercase;
  padding: 0 16px;
  flex: 1;
`;

const Arrow = styled(motion.span)`
  display: inline-block;
  flex-shrink: 0;
  width: 80px;
  height: 3px;
  background-color: ${({ theme }) => theme.COLORS.vermilion};
  position: relative;
  border-radius: 50px;
  justify-self: flex-end;

  &::after,
  &::before {
    content: "";
    display: block;
    position: absolute;
    border-radius: 50px;
    height: 3px;
    width: 10px;
    background-color: ${({ theme }) => theme.COLORS.vermilion};
    right: 0;
    transform-origin: right center;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
`;

const buttonAnim = {
  hoverable: {
    scale: 1.1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1,
    },
  },
};

const arrowAnim = {
  hoverable: {
    x: 8,
    transition: {
      repeat: Infinity,
      type: "spring",
    },
  },
};

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const Button = ({ label = null, href = null, onClick = () => {} }) => {
  const Wrapper = href ? StyledLink : React.Fragment;

  return (
    <Wrapper to={href}>
      <B variants={buttonAnim} whileHover="hoverable" onClick={onClick}>
        {label && <Label>{label}</Label>}
        <Arrow variants={arrowAnim} />
      </B>
    </Wrapper>
  );
};

export default Button;
