import * as React from "react";
import styled from "styled-components";
import { Section, FixedWidth } from "../../layout/Semantic";
import HKImage from "../../../assets/images/hk_bg.jpg";
import SHImage from "../../../assets/images/sh_bg.jpg";

const ImageWrapper = styled.div`
  flex-basis: 50%;
  flex-grow: 0;
  flex-shrink: 0;

  & > div {
    position: relative;
    height: 0;
    padding-bottom: ${({ ratio }) => (ratio ? ratio * 100 + "%" : "100%")};
  }

  & > div > div {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  & > div > div > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
const Cities = (props) => {
  return (
    <Section>
      <FixedWidth style={{ flexDirection: "row", width: "100%" }}>
        <ImageWrapper>
          <div>
            <div>
              <img alt="city of shanghai" src={SHImage} />
            </div>
          </div>
        </ImageWrapper>
        <ImageWrapper>
          <div>
            <div>
              <img alt="city of hong kong" src={HKImage} />
            </div>
          </div>
        </ImageWrapper>
      </FixedWidth>
    </Section>
  );
};

export default Cities;
