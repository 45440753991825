import styled from "styled-components";

const Triangle = styled.div`
  width: ${({ width }) => (width ? width + "px" : "100%")};
  height: ${({ height }) => (height ? height + "px" : "200px")};
  background-color: ${({ theme }) => theme.COLORS.abyss};
`;

export const LeftTopTriangle = styled(Triangle)`
  clip-path: polygon(0 0, 0 100%, 100% 0);
`;

export const RightTopTriangle = styled(Triangle)`
  clip-path: polygon(0 0, 100% 100%, 100% 0);
`;

export const LeftBottomTriangle = styled(Triangle)`
  clip-path: polygon(0 100%, 100% 100%, 0 0);
`;

export const RightBottomTriangle = styled(Triangle)`
  clip-path: polygon(0 100%, 100% 100%, 100% 0);
`;
