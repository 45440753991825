import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import v1 from "../../../assets/videos/video1.mp4";
import v2 from "../../../assets/videos/video2.mp4";
import v5 from "../../../assets/videos/video5.mp4";
import LogoFull from "../../../assets/images/svg/logo_full_light_shadow.inline.svg";
import Content from "../../../content/home.json";

const chooseRandomVideo = () => {
  const randomIndex = Math.floor(Math.random() * (2 - 0 + 1) + 0);
  return Array(3)
    .fill(false)
    .map((_, i) => {
      return i === randomIndex;
    });
};
const Header = styled(motion.header)`
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
`;

const VideoContainer = styled(motion.div)`
  flex: 1;
  display: flex;
  flex-direction: column;

  div {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    video {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
`;

const SingleVideoWrapper = styled(motion.div)`
  opacity: 0;
`;

const VideoDecorator = styled(motion.div)`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const VideoInteractor = styled(motion.div)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  & > * {
    flex: 1;
  }
`;

const VideoNav = styled(motion.a)`
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  text-decoration: none;
  justify-content: flex-end;
  align-items: center;
  padding: 20px 10px 96px;

  h2 {
    font-size: clamp(var(--s-default), var(--s-large), var(--s-large));
    font-weight: 400;
    color: ${({ theme }) => theme.COLORS.snow};
    text-decoration: none;
    margin: 32px 0;
    user-select: none;
    text-shadow: 0 5px 15px rgba(21, 28, 27, 15);
    transition: transform 0.2s ease;
    text-align: center;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0);

    h2 {
      transform: scale(1.2);
    }
  }
`;

const variants = {
  hidden: {
    opacity: 0,
    transition: { duration: 0.1 },
  },
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.3,
      duration: 0.1,
    },
  },
};

const Hero = (props) => {
  const [activeVideos, setActiveVideos] = React.useState(chooseRandomVideo());

  return (
    <Header style={{ height: "100vh", position: "relative" }}>
      <VideoContainer>
        <SingleVideoWrapper
          variants={variants}
          initial={{ opacity: 0 }}
          animate={activeVideos[0] ? "visible" : "hidden"}
        >
          <video muted autoPlay loop playsInline>
            <source src={v1} type="video/mp4" />
          </video>
        </SingleVideoWrapper>
        <SingleVideoWrapper
          variants={variants}
          initial={{ opacity: 0 }}
          animate={activeVideos[1] ? "visible" : "hidden"}
        >
          <video muted autoPlay loop playsInline>
            <source src={v2} type="video/mp4" />
          </video>
        </SingleVideoWrapper>
        <SingleVideoWrapper
          variants={variants}
          initial={{ opacity: 0 }}
          animate={activeVideos[2] ? "visible" : "hidden"}
        >
          <video muted autoPlay loop playsInline>
            <source src={v5} type="video/mp4" />
          </video>
        </SingleVideoWrapper>
      </VideoContainer>

      <VideoInteractor>
        <VideoNav
          href="#about"
          onMouseEnter={() => setActiveVideos([true, false, false])}
        >
          <h2>{Content.links.one}</h2>
        </VideoNav>
        <VideoNav
          href="#expertise"
          onMouseEnter={() => setActiveVideos([false, true, false])}
        >
          <h2>{Content.links.two}</h2>
        </VideoNav>
        <VideoNav
          href="#focus"
          onMouseEnter={() => setActiveVideos([false, false, true])}
        >
          <h2>{Content.links.three}</h2>
        </VideoNav>
      </VideoInteractor>
      <VideoDecorator initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        <LogoFull
          style={{
            width: "256px",
          }}
        />
      </VideoDecorator>
    </Header>
  );
};

export default Hero;
