import * as React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

const BaseContainer = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: ${({ size = 180 }) => size + "px"};
  height: ${({ size = 180 }) => size + "px"};
  z-index: 100;
`;

const InnerCircle = styled(BaseContainer)`
  background-color: ${({ theme }) => theme.COLORS.abyss};
  border-radius: 50%;
  position: absolute;
  flex-shrink: 0;
`;

const CircleWrapper = styled(InnerCircle)`
  background-color: transparent;
  height: 0;
  width: 0;
  z-index: 50;
`;

const Inner1 = styled(InnerCircle)`
  background-color: ${({ theme, bgColor }) =>
    bgColor ? theme.COLORS[bgColor] : theme.COLORS.darkest};
`;

const Inner2 = styled(InnerCircle)`
  background: ${({ theme, innerPattern }) => {
    return innerPattern
      ? `
  repeating-linear-gradient(
    to top right,
    ${theme.COLORS.rainyday} 0,
    ${theme.COLORS.rainyday} 1px,
    ${theme.COLORS.darker} 1px,
    ${theme.COLORS.darker} 8px
  )`
      : "unset";
  }};
  background-color: ${({ theme }) => theme.COLORS.darker};
  z-index: 30;
`;

const Inner3 = styled(InnerCircle)`
  background: linear-gradient(
    to right,
    ${({ theme }) => theme.COLORS.vermilion} 20%,
    ${({ theme }) => theme.COLORS.marine} 50%,
    ${({ theme }) => theme.COLORS.snow}
  );
  z-index: 20;
`;

const Inner4 = styled(InnerCircle)`
  background: linear-gradient(
    to top left,
    ${({ theme }) => theme.COLORS.vermilion} 25%,
    ${({ theme }) => theme.COLORS.marine} 50%,
    ${({ theme }) => theme.COLORS.snow}
  );
  z-index: 10;
`;

const Inner5 = styled(InnerCircle)`
  background-color: transparent !important;
  background: ${({ theme }) =>
    `
    repeating-linear-gradient(
      -115deg,
      #828282 0,
      #828282 1px,
      transparent 1px,
      transparent 8px
    )
  `};
  z-index: 5 !important;
`;

const CircleLabel = styled(motion.h3)`
  font-size: ${({ theme }) => theme.FONTS.sizes.large};
  color: ${({ theme }) => theme.COLORS.snow};
  z-index: 1000;
  text-align: center;
`;

const topVariants = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.3,
    },
  },
};
const viewVariants = {
  hidden: {
    scale: 0,
    opacity: 0,
  },
  show: {
    scale: 1,
    opacity: 1,
  },
};

const Circles = ({
  isInView = false,
  viewOverride = false,
  label = "A title",
  size = 182,
  innerPattern = false,
  bgColor = "darkest",
  extraPattern = false,
  thinner = false,
  ...props
}) => {
  return (
    <BaseContainer size={size} {...props}>
      <CircleWrapper
        variants={topVariants}
        animate={isInView && !viewOverride ? "show" : "hidden"}
      >
        <Inner2
          innerPattern={innerPattern}
          style={{ transform: "scale(0)", opacity: 0 }}
          variants={viewVariants}
          size={thinner ? size * 1.3 : size * 1.52}
        />
        <Inner3
          style={{ transform: "scale(0)", opacity: 0 }}
          variants={viewVariants}
          size={thinner ? size * 1.6 : size * 2.17}
        />
        <Inner4
          style={{ transform: "scale(0)", opacity: 0 }}
          variants={viewVariants}
          size={thinner ? size * 2 : size * 2.81}
        />
        {extraPattern && (
          <Inner5
            style={{ transform: "scale(0)", opacity: 0 }}
            variants={viewVariants}
            size={thinner ? size * 2.5 : size * 3.2}
          />
        )}
      </CircleWrapper>

      <Inner1
        bgColor={bgColor}
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        size={size}
      />

      <CircleLabel animate={{ opacity: 1 }} initial={{ opacity: 0 }}>
        {label}
      </CircleLabel>
    </BaseContainer>
  );
};

export default Circles;
