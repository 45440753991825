import * as React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import Content from "../../../content/home.json";

const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  margin-bottom: 56px;

  @media screen and (min-width: 959px) {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
  }

  padding: 20px;
`;
const Square = styled(motion.div)`
  width: 192px;
  height: 192px;
  flex-shrink: 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 18px;
`;

const SquareContainerLeft = styled(motion.div)`
  display: flex;
  flex-direction: row;
  max-width: 412px;
  min-width: 320px;
  width: 100%;
  margin: 0;
  position: relative;
`;

const SquareContainerBottomLeft = styled(SquareContainerLeft)`
  flex-direction: column;
  align-items: flex-end;
`;

const SquareContainerBottomRight = styled(SquareContainerLeft)`
  flex-direction: column;
  align-items: flex-start;
`;

const ShadowLeft = styled(motion.div)`
  clip-path: polygon(0 calc(100% - 192px), 100% 0%, 100% 192px, 0 100%);
  flex-shrink: 0;
  flex-grow: 1;
  background: linear-gradient(
    to top,
    ${({ theme }) => theme.COLORS.abyss} 2%,
    ${({ theme }) => theme.COLORS.marine} 50%,
    ${({ theme }) => theme.COLORS.vermilion} 80%
  );
`;

const ShadowBottom = styled(ShadowLeft)`
  clip-path: polygon(0 100%, calc(100% - 192px) 0, 100% 0, 192px 100%);
  width: 100%;
  background: linear-gradient(
    to bottom,
    ${({ theme }) => theme.COLORS.abyss},
    ${({ theme }) => theme.COLORS.marine} 20%,
    ${({ theme }) => theme.COLORS.vermilion} 75%
  );
`;

const ShadowBottomRight = styled(ShadowLeft)`
  clip-path: polygon(calc(100% - 192px) 100%, 0 0, 192px 0, 100% 100%);
  width: 100%;
  background: linear-gradient(
    to left,
    ${({ theme }) => theme.COLORS.abyss},
    ${({ theme }) => theme.COLORS.marine} 25%,
    ${({ theme }) => theme.COLORS.vermilion} 95%
  );
`;

const ContentTitle = styled(motion.span)`
  font-family: ${({ theme }) => theme.FONTS.family1};
  font-size: ${({ theme }) => theme.FONTS.sizes.large};
  margin: 4px 0;
`;

const ContentItems = styled(ContentTitle).attrs({ as: "ul" })`
  margin: 4px;
  padding: 0 0 0 8px;

  li {
    padding: 4px 0;
    font-family: ${({ theme }) => theme.FONTS.family2};
    font-size: ${({ theme }) => theme.FONTS.sizes.default};
  }
`;

const Descriptor = styled(motion.div)`
  position: absolute;
  top: calc(192px + 20px);
  width: 100%;
  background-color: white;
  padding: 18px;

  span {
    font-family: ${({ theme }) => theme.FONTS.family2};
    font-size: ${({ theme }) => theme.FONTS.sizes.default};
    color: ${({ theme }) => theme.COLORS.abyss};
  }
`;

const IndustrySquares = ({ isMobile = true, ...props }) => {
  return (
    <Wrapper>
      <SquareContainerLeft
        isMobile
        style={{
          top: isMobile ? "unset" : "75px",
          minHeight: isMobile ? "350px" : "480px",
        }}
      >
        <ShadowLeft />
        <Square>
          <ContentTitle>{Content.industry_focus.one.title}</ContentTitle>
          <ContentItems>
            {Content.industry_focus.one.points.map((p, i) => {
              return <li key={i}>{p}</li>;
            })}
          </ContentItems>
        </Square>
        <Descriptor>
          <span>{Content.industry_focus.one.explainer}</span>
        </Descriptor>
      </SquareContainerLeft>
      <SquareContainerBottomLeft
        isMobile
        style={{
          right: isMobile ? "unset" : "70px",
          top: isMobile ? "unset" : "150px",
          minHeight: isMobile ? "350px" : "480px",
        }}
      >
        <Square>
          <ContentTitle>{Content.industry_focus.two.title}</ContentTitle>
          <ContentItems>
            {Content.industry_focus.two.points.map((p, i) => {
              return <li key={i}>{p}</li>;
            })}
          </ContentItems>
        </Square>
        <ShadowBottom />
        <Descriptor
          style={{
            left: isMobile ? "unset" : "50%",
            top: "calc(192px + 40px)",
          }}
        >
          <span>{Content.industry_focus.two.explainer}</span>
        </Descriptor>
      </SquareContainerBottomLeft>
      <SquareContainerBottomRight
        style={{ minHeight: isMobile ? "350px" : "480px" }}
      >
        <Square>
          <ContentTitle>{Content.industry_focus.three.title}</ContentTitle>
          <ContentItems>
            {Content.industry_focus.three.points.map((p, i) => {
              return <li key={i}>{p}</li>;
            })}
          </ContentItems>
        </Square>
        <ShadowBottomRight />
        <Descriptor>
          <span>{Content.industry_focus.three.explainer}</span>
        </Descriptor>
      </SquareContainerBottomRight>
    </Wrapper>
  );
};

export default IndustrySquares;
